import Head from 'next/head';
import setResponseHeaders from '@buzzfeed/headers'
import Script from 'next/script'
import styles from './daddy.module.scss'

import logo from './img/dw-logo.png'
import daddyTitle from './img/daddy.png'
import daddy1 from './img/daddy-1006.jpg'
import daddy2 from './img/daddy-1007.jpg'
import daddy3 from './img/daddy-1008.jpg'
import daddy4 from './img/daddy-1009.jpg'
import daddy5 from './img/daddy-1010.jpg'
import daddy6 from './img/daddy-1011.jpg'
import daddy7 from './img/daddy-1012.jpg'
import daddy8 from './img/daddy-1013.jpg'
import daddy9 from './img/daddy-1014.jpg'
import daddy10 from './img/daddy-1015.jpg'
import daddy11 from './img/daddy-1016.jpg'
import daddy12 from './img/daddy-1017.jpg'

export function Daddy() {
  return (
    <>
    <Head>
    <meta property="og:image" content="https://img.buzzfeed.com/thumbnailer-prod-us-east-1/hive/binaries/491803.jpg" />
  </Head>
    <section className="min-h-full">
      <Script
          src="https://www.googletagmanager.com/gtag/js?id=G-99YTG2Q4E5"
          strategy="afterInteractive"
        />
        <Script id="google-analytics" strategy="afterInteractive">
          {`
            window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-99YTG2Q4E5');
          `}
        </Script>
      <Nav />
      <Content />
    </section>
    </>
  );
}

const Nav = () => {
  return (
      <section className={styles.nav}>
        <div className={`${styles.container} ${styles.navContent}`}>
          <div className={styles.logo}>
            <img alt="daddy whispers" src={logo} />
          </div>
          <div className={styles.side}>
            <a className={styles.topLink} href="https://www.buzzfeed.com/buzzfeedlabs/sugar-daddy-quiz">Get Your Match</a>
          </div>
        </div>
      </section>
  );
};
const Content = () => {
  return (
      <section className={`${styles.container} ${styles.content}`}>
          <div className={`${styles.contentBox} ${styles.imgGrid}`}>
              <div className={styles.imgGridContent}>
                  <div className={styles.imgGridWrapper}>
                    <div className={styles.imgItem}>
                        <div className={styles.imgItemInner}>
                        <img
                            alt="gallery"
                            src={daddy1} />
                        </div>
                    </div>
                    <div className={styles.imgItem}>
                        <div className={styles.imgItemInner}>
                        <img
                            alt="gallery"
                            src={daddy2} />
                        </div>
                    </div>
                    <div className={styles.imgItem}>
                        <div className={styles.imgItemInner}>
                        <img
                            alt="gallery"
                            src={daddy3} />
                        </div>
                    </div>
                    <div className={styles.imgItem}>
                        <div className={styles.imgItemInner}>
                        <img
                            alt="gallery"
                            src={daddy4} />
                        </div>
                    </div>
                    <div className={styles.imgItem}>
                        <div className={styles.imgItemInner}>
                        <img
                            alt="gallery"
                            src={daddy5} />
                        </div>
                    </div>
                    <div className={styles.imgItem}>
                        <div className={styles.imgItemInner}>
                        <img
                            alt="gallery"
                            src={daddy6} />
                        </div>
                    </div>
                    <div className={styles.imgItem}>
                        <div className={styles.imgItemInner}>
                        <img
                            alt="gallery"
                            src={daddy7} />
                        </div>
                    </div>
                    <div className={styles.imgItem}>
                        <div className={styles.imgItemInner}>
                        <img
                            alt="gallery"
                            src={daddy8} />
                        </div>
                    </div>
                    <div className={styles.imgItem}>
                        <div className={styles.imgItemInner}>
                        <img
                            alt="gallery"
                            src={daddy9} />
                        </div>
                    </div>
                    <div className={styles.imgItem}>
                        <div className={styles.imgItemInner}>
                        <img
                            alt="gallery"
                            src={daddy10} />
                        </div>
                    </div>
                    <div className={styles.imgItem}>
                        <div className={styles.imgItemInner}>
                        <img
                            alt="gallery"
                            src={daddy11} />
                        </div>
                    </div>
                    <div className={styles.imgItem}>
                        <div className={styles.imgItemInner}>
                        <img
                            alt="gallery"
                            src={daddy12} />
                        </div>
                    </div>
                  </div>
              </div>
          </div>
          <div className={styles.contentBox}>
              <div className={styles.boxInner}>
                  <div className={styles.boxInnerContent}>
                    <img alt="daddy whispers" src={daddyTitle} />
                    <p className={styles.boxDescription}>Ready to skip the small talk and get straight to the sugar? Our platform offers a safe and discreet space for sugar babies to explore the exciting world of sugar dating! Our extensive network of wealthy and successful sugar daddies are waiting to spoil and pamper you rotten.</p>
                    <a className={styles.boxBtn} href="https://www.buzzfeed.com/buzzfeedlabs/sugar-daddy-quiz" role="button" >GET YOUR MATCH</a>
                    <p className={styles.boxFooterText}>No credit card required. The service is always free for Babies!</p>
                  </div>
              </div>
          </div>
      </section>
  );
};

export async function getServerSideProps({ req, res }) {
  setResponseHeaders({
    req, res, maxAge: 86400
  });
  return { props: {
    pageInfo: {
        context_page_type: 'standalone',
        context_page_id: 'sugar-daddy',
        allowRobots: true
      },
  } }
}

export default Daddy;
